@import './_general_styling.scss';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color:$dark-grey lightgrey;
  scrollbar-width: thin;
}

body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;

}


header .NavBarContainer .ant-menu {
    color: rgba(0,0,0,.65)!important;
}


.titleSection{
  margin-top: 40px;
}

.mainTitle{
  padding-top: 30px;
}




@media only screen and (min-width: 992px) {

  .ant-menu-submenu-open, .ant-menu-submenu-title:hover, .ant-menu-horizontal>.ant-menu-submenu:hover{
    color: #f4993d!important;
    border-bottom: 2px solid #f4993d!important;

  }

  .ant-menu-submenu-open{
    color: #f4993d!important;
    border-bottom: 2px solid #f4993d!important;
  }

  .ant-menu-submenu-active{
    color: #f4993d!important;
    border-bottom: 2px solid #f4993d!important;
  }

  .ant-menu-item-active, .ant-menu-item:hover{
    color: #f4993d!important;
  }

  .ant-menu-item-active, .ant-menu-item:hover a{
    color: #f4993d!important;
  }

}


@media only screen and (max-width: 750px) {

  .ant-menu-submenu-vertical-left .ant-menu-submenu-title:hover, .ant-menu-submenu-open{
    color: #fff!important;
    background: #f4993d!important;
  }

  .ant-menu .ant-menu-submenu-open{
    background: transparent!important;
  }


}
